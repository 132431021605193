<template>
    <div class="__main_card_container">
        <div class="_main_card_header">
            <div class="__header_inner admin_header_padding">
                <h2>
                  
                </h2>
                <el-dropdown trigger='click'>
                    <el-button id="create_new_account">
                        Create new message
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item> <button class="__dropdown_btn" @click="messageClients()">Message Clients</button> </el-dropdown-item>
                        <el-dropdown-item> <button class="__dropdown_btn" @click="messageDebtors()">Message Debtors</button> </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="main_card_body">
            
        </div>
        <SendMessageToClients ref="sendmessagetoclient" />
        <MessageDebtors ref="messageDebtors" />
    </div>
</template>

<script>
import $ from 'jquery'
import SendMessageToClients from './SendMessageToClients.vue'
import MessageDebtors from './MessageDebtors'
import appConfig from '@/app.config'

export default {
    page: {
		title: 'Mass messages',
		meta: [{ name: 'description', content: appConfig.description }],
	},
    name:'users',
       components: {
           SendMessageToClients,
           MessageDebtors
        },
    data() {
        return {

        }
    },
    computed: {
        
    },
    methods: {
        messageClients() {
            let element = this.$refs.sendmessagetoclient.$el
            $(element).modal('show')
        },
        messageDebtors(){
            let element = this.$refs.messageDebtors.$el
            $(element).modal('show')
        }
    },
    mounted() {
        
    }
}
</script>

<style scoped>
#create_new_account{
    display: flex;
    align-items: center;
    padding: 8px 23px;
    width: 187px;
    height: 38px;
    background: #F7921C;
    border-radius: 5px;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #FFFFFF;
    outline: none;
    border: none;
}
.__dropdown_btn{
    outline: none;
    border:none;
    width:100%;
    background: transparent;
    height: 100%;
}
</style>