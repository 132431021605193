<template>
    <div class="modal fade" id="sendmessagetoclient" tabindex="-1" role="dialog" aria-labelledby="contact"
        aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered " role="document">
            <div class="modal-content d-flex align-items-center" v-if="success">
                <successMessage :message="success" />
                <button @click="hideModal" class="__close__success__modal">Close</button>
            </div>
            <div class="modal-content pb-3" v-if="!success">
                <div class="modal-header pt-4 pb-2">
                    <h5 class="modal-title " id="exampleModalLabel">
                        Send message to clients
                    </h5>
                </div>
                <div class="modal-body m-0">
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <el-form :model="formData" :rules="rules" ref="ruleForm"
                                @submit.prevent.native="checkEnter">
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <el-form-item label="Select Group" prop="type">
                                            <el-select style="width:100%" v-model="formData.type" multiple filterable
                                                placeholder="Select Group">
                                                <el-option v-for="(ind, key) in industries" :key="key"
                                                    :label="`${ind.name}`" :value="ind.id"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form_input_container">
                                            <el-form-item label="Message" prop="message">
                                                <el-input type="textarea" rows="10" autocomplete="off"
                                                    placeholder="Please select a message Category"
                                                    v-model.number="formData.message"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div class="col-md-12 d-flex">
                                        <p class="text_description">
                                            {{ formData.message.length }} characters entered
                                        </p>
                                        <p class="text_description pl-4" v-if="findNumberOfRecipient">
                                            {{ findNumberOfRecipient }} message per recipient
                                        </p>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </div>
                    <div class="row justify-content-center mb-4">
                        <div class="col-md-12 modal__footer__btns d-flex justify-content-between mx-3">
                            <button style="width:123px" class="cancel" @click="hideModal">Cancel</button>
                            <el-button style="" type="primary" :loading="loading" id="cancelinput"
                                @click="submitAction('ruleForm')">
                                Send Message
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/state/store.js'
import $ from 'jquery'
import industries from '@/helpers/adminMessageGroups.js'
export default {
    data() {
        return {
            success: '',
            error: '',
            loading: false,
            formData: {
                type: [],
                message: '',
                perMessage: 0
            },

            rules: {
                message: [
                    { required: true, message: 'Please Enter your message.', trigger: 'change' },
                ],
                type: [
                    { required: true, message: 'Please Select Industry type.', trigger: 'change' },
                ]
            }
        }
    },
    computed: {
        industries() {
            return industries;
        },
        findNumberOfRecipient() {
            let { message } = this.formData
            if (message.length == 0) {
                return 0
            }
            if (message.length <= 160) {
                this.formData.perMessage = 1
                return 1
            }
            else {
                if (message.body.length > 160) {
                    this.formData.perMessage = Math.ceil(message.body.length / 153)
                }
            }
            // } else if(message.length > 160 &&  message.length < 320 ) {
            //      this.formData.perMessage = 2
            //     return 2
            // } else if( message.length > 320 && message.length < 460 ) {
            //      this.formData.perMessage = 3
            //     return 3
            // }
        },
    },
    methods: {

        hideModal() {
            $('#sendmessagetoclient').modal('hide')
            this.error = ''
            this.success = ''
            this.loading = false
            this.formData = {
                type: [],
                message: ''
            }

        },
        submitAction(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.success = ''
                    delete this.formData.perMessage
                    return store.dispatch('admin/massMessage/messageClients', this.formData)
                        .then(message => {
                            this.loading = false
                            this.success = message
                        }).catch(err => {
                            this.loading = false
                            if (err?.response?.data == undefined) {
                                this.errors = "Network error try again."
                            }
                        })

                } else {
                    this.loading = false;
                    return false;
                }
            });

        }
    },
    mounted() {
        this.error = ''
        this.success = ''
        this.loading = false
        this.formData = {
            type: [],
            message: ''
        }
        //   store.dispatch('admin/massMessage/getIndustries').then( data => console.info(data))
    }
}
</script>
<style scoped>
.text_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484A4F;
}
</style>